import React from 'react';
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc';
import DataEmailForm from 'components/data-prep/data-email-form';
import './section-call-to-action.sass';

const SectionCallToAction = ({ data, updatePlatformRegistration, id }) => (
  <div className="SectionCallToAction k-gradient-green-blue">
      <h2 dangerouslySetInnerHTML={{ __html: data.title}} />
      <DataEmailForm call_to_action={data.call_to_action} updatePlatformRegistration={updatePlatformRegistration} id={`SectionCallToAction-${id}`}/>
  </div>
)

export default withSectionTracking(React.memo(SectionCallToAction))