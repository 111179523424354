import React from "react"
import { graphql } from "gatsby"
import { connect } from 'react-redux'
import { UPDATE_PLATFORM_REGISTRATION } from '../store/actions'
import SEO from 'components/seo/seo'
import Layout from 'components/layout'
import Hero from 'components/global/hero'
import Author from 'components/author/author'
import SectionCallToAction from 'components/data-prep/section-call-to-action/section-call-to-action'
import './templates.sass'
import 'style/plugins/jupyter.css'

const convert_inline_images = (html) => {
  //  finds kortical notebook image format occurances
  const regex = /(?<=<p>{img_src=)(.*?)(?=}<\/p>)/g
  let matches = html.match(regex)

  if(!matches || !matches.length){
    return html;
  }

  matches.forEach(match => {
    match = match.replace (/^/,"<p>{img_src=") + '}</p>';

    const image_obj = match.split('&#x27;');
    const image_src = image_obj[1];
    const alt = image_obj[3];
    const classes = image_obj[5];

    html = html.replace(match, `<img src=${image_src} alt=${alt} class='${classes}' />`)
  })

  return html
}

const convert_h3 = (html) => {
  //  creates space under h3 tag as it is used in other parts of the site
  const regex = /(?<=<h3>)(.*?)(?=<\/h3>)/g
  let matches = html.match(regex);

  if(!matches || !matches.length){
    return html;
  }

  matches.forEach(match => {
    match = match.replace (/^/,"<h3>") + '</h3>';
    const match_updated = match.replace (/^/,"<div class='mb-3'>") + '</div>';

    html = html.replace(match, match_updated)
  })

  return html
}

const JupyterArticle = ({
  updatePlatformRegistration,
  data: {
    landingPagesYaml,
    jupyterNotebook: {
      json: { metadata },
      html,
    },
  },
}) => {

  html = convert_inline_images(html);
  html = convert_h3(html);


  return (
    <Layout bodyClass="k-dark-hero-page">
      <SEO
          title={`${metadata.title} | Article`}/>
      <div className="JupyterArticle">
        <Hero image={metadata.image} className="Hero--article" alt={`Background image for ${metadata.title}`} is_notebook={1}>
            <div className="container-fluid">
                <div className="k-max-width--1">
                    <h1 className="mb-0 text-light">{metadata.title}</h1>
                    {metadata.subtitle.length ? <h2 className="mb-4 text-light font-weight-normal">{metadata.subtitle}</h2> : <div className="mb-4"></div>}
                    <Author author={metadata.author} className="text-light"/>
                </div>
            </div>
        </Hero>

        <div className="container-fluid my-5">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>

        <SectionCallToAction data={landingPagesYaml.footer_call_to_action}
                             id="JupyterArticle"
                             updatePlatformRegistration={updatePlatformRegistration}
                             sectionId="Jupyter-article__call-to-action-section"/>
      </div>
    </Layout>
  )
}

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(null, mapDispatchToProps)(JupyterArticle)

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    jupyterNotebook(fields: { slug: { eq: $slug } }) {
      json {
        metadata {
          title
          subtitle
          author
          image
        }
      }
      html
    }
    landingPagesYaml {
      footer_call_to_action {
        title
        input_text
        call_to_action
      }
    }
  }
`
